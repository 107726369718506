import { FC } from 'react'
import { FilterInputProps } from '../filterTypes'
import { Option } from './EnumFilter'
import { FilterInputs } from './FilterInputs'

type InputProps = {
  frontEndEnum?: Record<string, string | Option>
  formatEnums: (enums?: string[]) => string[]
  showSearch?: boolean
}

export const FrontEndEnumFilterInputs: FC<FilterInputProps<string, InputProps>> = ({
  filters,
  setFilters,
  frontEndEnum,
  formatEnums,
  showSearch,
}) => {
  const enumValues = frontEndEnum && Object.values(frontEndEnum).sort()
  const props = {
    ...(filters ? { filters } : {}),
    setFilters,
    ...(enumValues ? { enumValues } : {}),
    formatEnums,
    showSearch,
  }
  return <FilterInputs {...props} />
}
