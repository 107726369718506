import { FC, ReactElement, useState } from 'react'
import { Filter, FilterConfig, PaginationConfig } from '@netpurpose/types'
import Button from '../../Button'
import Spacer from '../../Spacer'
import { FilterInputProps, FilterProps } from '../filterTypes'
import { SortControl } from '../SortControl'
import {
  ButtonContainer,
  Container,
  ControlsContainer,
  LinkText,
  SectionContainer,
} from './FilterContainer.style'

type Props<Model, FilterValue, InputProps> = FilterProps<Model, InputProps> & {
  filterConfig: FilterConfig<Model>
  paginationConfig: PaginationConfig
  defaultValue: Filter<FilterValue>[]
  renderInput: FC<FilterInputProps<FilterValue, InputProps>>
  sortIsAlphabetical?: boolean
}

const FilterContainer = <Model, FilterValue, InputProps>({
  confirm,
  filterConfig,
  paginationConfig,
  dataIndex,
  defaultValue,
  renderInput: RenderInput,
  visible,
  inputProps,
  sortIsAlphabetical = false,
}: Props<Model, FilterValue, InputProps>): ReactElement => {
  const [filters, setFilters] = useState(filterConfig.filters[dataIndex] || defaultValue)
  const isActive = filterConfig.isActive(dataIndex)
  const cannotSubmit = !filters?.[0]?.value

  const handleClear = () => {
    if (!isActive) {
      return
    }
    filterConfig.clearFilter(dataIndex)
    setFilters(defaultValue)
    confirm()
  }
  const handleCancel = () => confirm()
  const handleConfirm = () => {
    paginationConfig.setCurrentPage(1)
    filterConfig.setFilters<FilterValue>(dataIndex, filters as Filter<FilterValue>[])
    confirm()
  }

  return (
    <Container>
      <ControlsContainer>
        <LinkText disabled={!isActive} onClick={handleClear}>
          Clear
        </LinkText>
        <SortControl
          dataIndex={dataIndex}
          filterConfig={filterConfig}
          sortIsAlphabetical={sortIsAlphabetical}
        />
      </ControlsContainer>
      <Spacer orientation="horizontal" spacing={0} />
      <SectionContainer>
        <RenderInput
          filters={filters as Filter<FilterValue>[]}
          setFilters={setFilters}
          visible={visible}
          onConfirm={handleConfirm}
          {...inputProps}
        />
      </SectionContainer>
      <Spacer orientation="horizontal" spacing={0} />
      <SectionContainer>
        <ButtonContainer>
          <Button level="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button level="primary" onClick={handleConfirm} disabled={cannotSubmit}>
            OK
          </Button>
        </ButtonContainer>
      </SectionContainer>
    </Container>
  )
}

export default FilterContainer
