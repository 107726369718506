import { FC } from 'react'
import styled from 'styled-components'
import { Icon } from '../../Icon'

type Props = {
  active: boolean
}

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FilterIcon: FC<Props> = ({ active }) => (
  <Container>
    <Icon icon="Filter" color={active ? 'black' : 'architecture4'} alt="filter" />
  </Container>
)

export default FilterIcon
