import { ReactElement } from 'react'
import { Operator } from '@netpurpose/types'
import FilterContainer from '../FilterContainer'
import { FilterProps } from '../filterTypes'
import { BackEndFilterInputs } from './BackEndEnumFilterInputs'
import { FrontEndEnumFilterInputs } from './FrontEndEnumFilterInputs'

export type Option = { label: string; value: string }

type InputProps = {
  enumName?: string
  frontEndEnum?: Record<string, string | Option>
  formatEnums: (enums?: string[]) => string[]
  showSearch?: boolean
}

const EnumFilter = <Model,>(props: FilterProps<Model, InputProps>): ReactElement => {
  const { inputProps } = props
  const FilterInputs = inputProps.enumName ? BackEndFilterInputs : FrontEndEnumFilterInputs
  return (
    <FilterContainer
      defaultValue={[{ operator: Operator.Equals, value: '' }]}
      renderInput={FilterInputs}
      sortIsAlphabetical
      {...props}
    />
  )
}

export default EnumFilter
