import { FC, ReactElement, useCallback } from 'react'
import { Operator } from '@netpurpose/types'
import FilterContainer from '../FilterContainer'
import { Container, Select } from '../filterStyles.style'
import { FilterInputProps, FilterProps } from '../filterTypes'

const FilterInputs: FC<FilterInputProps<string>> = ({ filters, setFilters }) => {
  const { operator, value } = filters?.[0] || { operator: Operator.Equals, value: '' }

  const handleValueChange = useCallback(
    (newValue: string) => setFilters([{ operator: Operator.Equals, value: newValue }]),
    [setFilters],
  )

  return (
    <Container>
      <Select defaultValue={Operator.Equals} value={operator} disabled size="large">
        <Select.Option value={Operator.Equals}>Equals</Select.Option>
      </Select>
      <Select
        value={value || 'True'}
        size="large"
        onChange={(val) => handleValueChange(val as string)}
      >
        <Select.Option value="True" key="True">
          True
        </Select.Option>
        <Select.Option value="False" key="False">
          False
        </Select.Option>
      </Select>
    </Container>
  )
}

export const BooleanFilter = <Model,>(props: FilterProps<Model>): ReactElement => (
  <FilterContainer
    defaultValue={[{ operator: Operator.Equals, value: 'True' }]}
    renderInput={FilterInputs}
    {...props}
  />
)
