import { FC, useCallback, useEffect } from 'react'
import { isString, Operator } from '@netpurpose/types'
import { convertStringToEnum } from '@netpurpose/utils'
import { Container, Select } from '../filterStyles.style'
import { FilterInputProps } from '../filterTypes'
import { Option } from './EnumFilter'

type InputProps = {
  enumValues?: (string | Option)[]
  formatEnums?: (enums?: string[]) => string[]
  showSearch?: boolean | undefined
}

const getValue = (val: string | Option) => (isString(val) ? val : val.value)
const getLabel = (val: string | Option) => (isString(val) ? val : val.label)

export const FilterInputs: FC<FilterInputProps<string, InputProps>> = ({
  filters,
  setFilters,
  enumValues,
  formatEnums,
  showSearch = false,
}) => {
  const { operator, value } = filters?.[0] || { operator: Operator.Equals, value: '' }
  const formattedEnumValues = formatEnums ? formatEnums(enumValues?.map(getValue)) : enumValues

  const handleOperatorChange = useCallback(
    (newOperator: string) =>
      setFilters([{ operator: convertStringToEnum<Operator>(newOperator, Operator), value }]),
    [setFilters, value],
  )

  const handleValueChange = useCallback(
    (newValue: string) => setFilters([{ operator, value: newValue }]),
    [setFilters, operator],
  )

  useEffect(() => {
    if (!value && enumValues?.[0]) {
      handleValueChange(getValue(enumValues?.[0]))
    }
  }, [enumValues, handleValueChange, value])

  return (
    <Container>
      <Select
        defaultValue={Operator.Equals}
        value={operator}
        // FIXME: as below, the typing of the onChange handler seems a bit off
        // @ts-expect-error
        onChange={handleOperatorChange}
        size="large"
      >
        <Select.Option key={Operator.Equals} value={Operator.Equals}>
          Equals
        </Select.Option>
        <Select.Option key={Operator.DoesNotEqual} value={Operator.DoesNotEqual}>
          Does not equal
        </Select.Option>
      </Select>
      {/* Antd types are a bit dodgy if you're only using the value, not the option as well */}
      {/* @ts-expect-error */}
      <Select value={value} size="large" onChange={handleValueChange} showSearch={showSearch}>
        {(enumValues || []).map((enumValue, index) => {
          const optionValue = getValue(enumValue)
          const item = formattedEnumValues?.[index]
          return (
            <Select.Option label={getLabel(enumValue)} value={optionValue} key={optionValue}>
              {item && getLabel(item)}
            </Select.Option>
          )
        })}
      </Select>
    </Container>
  )
}
