import { add, isAfter, isBefore, sub } from 'date-fns'
import { Formik } from 'formik'
import { FC, ReactElement } from 'react'
import { Operator } from '@netpurpose/types'
import { DatePicker } from '../../FormInput'
import FilterContainer from '../FilterContainer'
import { Container, Select } from '../filterStyles.style'
import { FilterInputProps, FilterProps } from '../filterTypes'
import { Label } from './DateFilter.style'

const FilterInputs: FC<FilterInputProps<Date>> = ({ filters, setFilters }) => {
  const startFilter = filters && filters.find((f) => f.operator === Operator.After)
  const endFilter = filters && filters.find((f) => f.operator === Operator.Before)

  const handleChangeStartDate = (newStartDate: Date | null) => {
    const maybeEndFilter = endFilter ? [endFilter] : []
    if (newStartDate === null) {
      return setFilters([...maybeEndFilter])
    }

    return setFilters([
      {
        operator: Operator.After,
        // NOTE: we need to include the previous day so that the BE request is made inclusive
        value: sub(newStartDate, { days: 1 }),
      },
      ...maybeEndFilter,
    ])
  }

  const handleChangeEndDate = (newEndDate: Date | null) => {
    const maybeStartFilter = startFilter ? [startFilter] : []
    if (!newEndDate) {
      return setFilters([...maybeStartFilter])
    }
    return setFilters([
      {
        operator: Operator.Before,
        // NOTE: we need to include the next day so that the BE request is made inclusive
        value: add(newEndDate, { days: 1 }),
      },
      ...maybeStartFilter,
    ])
  }

  return (
    <Formik
      initialValues={{
        startDate: startFilter?.value,
        endDate: endFilter?.value,
      }}
      onSubmit={() => {}}
    >
      <Container>
        <Select defaultValue="Date range" value="Date range" disabled size="large">
          <Select.Option value="Date range">Date range</Select.Option>
        </Select>
        <Label>Starting at</Label>
        <DatePicker
          name="startDate"
          onChange={handleChangeStartDate}
          disabledDate={(d: number | Date) =>
            endFilter ? isAfter(d, endFilter.value as Date) : false
          }
        />
        <Label>Up to</Label>
        <DatePicker
          name="endDate"
          onChange={handleChangeEndDate}
          disabledDate={(d: number | Date) =>
            startFilter ? isBefore(d, startFilter.value as Date) : false
          }
        />
      </Container>
    </Formik>
  )
}

const DateFilter = <T,>(props: FilterProps<T>): ReactElement => {
  return <FilterContainer defaultValue={[]} renderInput={FilterInputs} {...props} />
}

export default DateFilter
