import { Input as AntInput, InputNumber as AntInputNumber, Select as AntSelect } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  > * + * {
    margin-top: 0.5rem;
  }
`
export const Select = styled(AntSelect)`
  width: 100%;
  > .ant-select-selector.ant-select-selector {
    border-radius: ${({ theme }) => theme.border.radius};
  }
`

export const Input = styled(AntInput)`
  border-radius: ${({ theme }) => theme.border.radius};
`

export const InputNumber = styled(AntInputNumber)`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius};
`
