import { InputRef } from 'antd'
import { ChangeEventHandler, FC, ReactElement, useEffect, useRef } from 'react'
import { Filter, Operator } from '@netpurpose/types'
import { noop } from '@netpurpose/utils'
import FilterContainer from '../FilterContainer'
import { Container, Input, Select } from '../filterStyles.style'
import { FilterInputProps, FilterProps } from '../filterTypes'

const FilterInputs: FC<FilterInputProps<string>> = ({
  filters,
  setFilters,
  visible,
  onConfirm,
}) => {
  const inputRef = useRef<InputRef>(null)
  useEffect(() => {
    setTimeout(() => inputRef.current?.focus({ cursor: 'all' }))
  }, [visible])

  const { operator, value } = filters?.[0] || { operator: Operator.Equals, value: '' }

  const handleValueChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setFilters([{ operator, value: e.target.value }])

  return (
    <Container>
      <Select defaultValue={Operator.Contains} value={operator} disabled size="large">
        <Select.Option value={Operator.Contains}>Contains</Select.Option>
        <Select.Option value={Operator.Equals}>Equals</Select.Option>
      </Select>
      <Input
        value={value}
        onChange={handleValueChange}
        ref={inputRef}
        onPressEnter={onConfirm || noop}
        size="large"
      />
    </Container>
  )
}

const AlphabetFilter = <T,>(props: FilterProps<T>): ReactElement => {
  const defaultValue = (
    props.inputProps as unknown as { defaultValue: Filter<string>[] | undefined }
  )?.defaultValue

  return (
    <FilterContainer
      defaultValue={defaultValue || [{ operator: Operator.Contains, value: '' }]}
      renderInput={FilterInputs}
      sortIsAlphabetical
      {...props}
    />
  )
}

export default AlphabetFilter
