import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 16.5rem;
  // any changes to this border radius has match with .ant-table-filter-dropdown in GlobalStyles.ts
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  background: ${({ theme }) => theme.colors.bgSecondary};
  border: 1px solid ${({ theme }) => theme.colors.architecture3};
`
export const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.25rem;
`
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 1.25rem;
  }
`

export const LinkText = styled.div<{ disabled: boolean }>`
  font-weight: 700;
  font-size: 0.75rem;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.typeTertiary : theme.colors.actionLink};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
