import { FC } from 'react'
import { useEnum } from '@netpurpose/api'
import { FilterInputProps } from '../filterTypes'
import { FilterInputs } from './FilterInputs'

type InputProps = {
  enumName?: string
  formatEnums: (enums?: string[]) => string[]
}

export const BackEndFilterInputs: FC<FilterInputProps<string, InputProps>> = ({
  filters,
  setFilters,
  enumName,
  formatEnums,
}) => {
  if (!enumName) {
    throw new Error('No enumName provided')
  }

  const { data: enumValues } = useEnum(enumName)

  return (
    <FilterInputs
      {...{
        ...(filters ? { filters } : {}),
        setFilters,
        ...(enumValues ? { enumValues } : {}),
        formatEnums,
      }}
    />
  )
}
