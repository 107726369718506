import { InputNumberProps } from 'antd'
import { FC, ReactElement, useEffect, useRef } from 'react'
import { Operator } from '@netpurpose/types'
import { noop } from '@netpurpose/utils'
import FilterContainer from '../FilterContainer'
import { Container, InputNumber, Select } from '../filterStyles.style'
import { FilterInputProps, FilterProps } from '../filterTypes'

type InputProps = {
  precision?: number
  type?: 'percentage' | 'millions'
}

const getFormatProps = (type?: string) => {
  switch (type) {
    case 'percentage':
      return {
        formatter: (value?: number | string) => `${value}%`,
        parser: (value?: string) => (value ? value.replace('%', '') : ''),
      }
    default:
      return {}
  }
}

const getNumberTransforms = (type?: string) => {
  switch (type) {
    case 'percentage':
      return {
        parse: (value?: number) => value && value / 100,
        format: (value?: number) => value && value * 100,
      }
    case 'millions':
      return {
        parse: (value?: number) => value && value * 1000000,
        format: (value?: number) => value && value / 1000000,
      }
    default:
      return {
        parse: (value?: number) => value,
        format: (value?: number) => value,
      }
  }
}

const FilterInputs: FC<FilterInputProps<number | undefined, InputProps>> = ({
  filters,
  setFilters,
  visible,
  onConfirm,
  precision,
  type,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    setTimeout(() => inputRef.current?.focus())
  }, [visible])

  const { parse, format } = getNumberTransforms(type)
  const { operator, value } = filters?.[0] || { operator: Operator.Equals, value: undefined }

  const handleOperatorChange = (newOperator: Operator) => {
    setFilters([{ operator: newOperator, value }])
  }

  const handleValueChange: InputNumberProps['onChange'] = (newValue) =>
    setFilters([{ operator, value: parse(newValue as number) }])

  const formatProps = getFormatProps(type)

  return (
    <Container>
      {/* Antd types are a bit dodgy if you're only using the value, not the option as well */}
      {/* @ts-expect-error */}
      <Select onChange={handleOperatorChange} value={operator} size="large">
        {!precision && <Select.Option value={Operator.Equals}>Equals</Select.Option>}
        <Select.Option value={Operator.GreaterThan}>Greater than</Select.Option>
        <Select.Option value={Operator.LessThan}>Less than</Select.Option>
      </Select>
      <InputNumber
        value={format(value) || null}
        onChange={handleValueChange}
        ref={inputRef}
        onPressEnter={onConfirm || noop}
        size="large"
        precision={precision || 0}
        {...formatProps}
      />
    </Container>
  )
}

const NumberFilter = <T,>(props: FilterProps<T, InputProps>): ReactElement => {
  const { inputProps } = props
  return (
    <FilterContainer
      defaultValue={[
        {
          operator:
            typeof inputProps.precision === 'number' ? Operator.GreaterThan : Operator.Equals,
          value: undefined,
        },
      ]}
      renderInput={FilterInputs}
      {...props}
    />
  )
}

export default NumberFilter
